// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'http://localhost:3002/api/v1',
  appStartDate: '2019-09-02',
  security: {
    secretKeyByJwt: '53803997877C7CFFB63D59961F2985A0507C2287DCE4A823E4C99689760993E8'
  },
  testAccounts: [
    'androidPruebas',
    'Azteca-Cobranza',
    'Azteca_Api',
    'Azteca-Api',
    'Azteca',
    'BAZTECA_API',
    'BAZTECA_DLR',
    'BAZTECA_COBRANZA_DLR'
  ],
  validAccounts: [
    'BAZTECA',
    'BAZTECA-COBRANZA',
    'BAZTECA_ENM_MKT',
    'BAZTCAENM-COBRANZA',
    'BAZTECA-ALNOVA',
    'BAZTECA_ENM_ALNOVA'
  ],
  omr: [
    'telcel',
    'movistar',
    'at&t'
  ],
  reportsFolder: 'http://localhost:8080',
  filePreferredExtension: 'csv.zip'
};
