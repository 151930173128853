import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { AuthGuard } from '../guards/auth.guard';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {
  passwordChangeForm: FormGroup;
  loading = false;
  submitted = false;
  user$
  errors = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.user$ = this.authenticationService.currentUserValue;

    this.passwordChangeForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirm: ['', [Validators.required, Validators.minLength(8)]]
    })
  }

  get f() { return this.passwordChangeForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.passwordChangeForm.invalid) {
        return;
      }

      this.loading = true;

      this.userService.passwordChange({
        ...this.passwordChangeForm.value,
        user: this.user$.user_name,
      }).then(response => {
        if (response.success) {
          this.toastr.success(response.message);
          this.router.navigate(['/home/landing']);
        } else {
          this.toastr.error('Ocurrió un error al actualizar la contraseña, por favor intente otra vez.')
        }
      })
      .catch(err => {
        this.errors = err.errors || [];

        this.toastr.error('Ocurrió un error al actualizar la contraseña', err.message)
      })
      .finally(() => {
        this.loading = false;

        setTimeout(() => {
          this.submitted = false;
          this.errors = [];
        }, 1500)
      })

  }

}
