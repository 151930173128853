import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { AuthGuard } from '../guards/auth.guard';
import { NotifierService } from '../services/notifier.service';
import { RouteInfo } from '../interfaces/RouteInfo';

declare const $: any;
export const ROUTES: RouteInfo[] = [
    {
      path: '/home/landing',
      title: 'Inicio',
      icon: 'pe-7s-home',
      class: '',
      roles: false
    },
    {
      path: '/home/dashboard',
      title: 'Dashboard',
      icon: 'pe-7s-graph',
      class: '',
      roles: ['root']
    },
    {
      path: '/home/messages',
      title: 'Mensajes',
      icon: 'pe-7s-mail',
      class: '',
      roles: ['root', 'client', 'client_administrator', 'internal_administrator']
    },
    {
      path: '/home/report',
      title: 'Reportes',
      icon: 'pe-7s-graph1',
      class: '',
      roles: ['client', 'client_administrator', 'internal_administrator']
    },
    {
      path: '/home/rates',
      title: 'Tarifas',
      icon: 'pe-7s-cash',
      class: '',
      roles: ['root', 'internal_administrator']
    },
    {
      path: '/home/accounts',
      title: 'Cuentas',
      icon: 'pe-7s-users',
      class: '',
      roles: ['root', 'internal_administrator']
    }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private notifier: NotifierService,
    private auth: AuthGuard,
    private element: ElementRef
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.toggleButton = document.getElementsByClassName('navbar-toggle')[0];
    this.notifier.notifier$.pipe().subscribe(message => {
      this.sidebarVisible = message ? !!message.visible : this.sidebarVisible;
    });
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  }

  sidebarOpen() {
      const toggleButton = this.toggleButton;
      const body = document.getElementsByTagName('body')[0];
      setTimeout(function() {
          toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');

      this.sidebarVisible = true;
  }

  sidebarClose() {
      const body = document.getElementsByTagName('body')[0];
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.notifier.notify({visible: true});
      this.sidebarOpen();
    } else {
      this.notifier.notify({visible: false});
      this.sidebarClose();
    }
  }

  logout() {
    this.authenticationService.logout();
  }
}
