import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotifierService {
  private notifier = new BehaviorSubject<any>(null);

  // No se utiliza directamente el BehaviorSubject (best practices)
  // Se canaliza su uso a través de un observable que será público.
  // Este observable llamará quién quiera ver el último mensaje que se dejó.
  notifier$ = this.notifier.asObservable();

  constructor() { }

  // Almacenar mensaje, listo para mostrarlo a quién lo pida.
  notify(message) {
    // function que llamará quien quiera transmitir un mensaje.
    this.notifier.next(message);
  }
}
