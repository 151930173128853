import { Component, OnInit, ElementRef, Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import { AuthGuard } from '../../guards/auth.guard';
import { ROUTES } from '../../sidebar/sidebar.component';
import { environment } from '../../../environments/environment';
import { ReportsService } from '../../services/reports.service';
import { NotifierService } from '../../services/notifier.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  // moduleId: module.id,
  // tslint:disable-next-line: component-selector
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private toggleButton: any;
  private sidebarVisible: boolean;
  route: any;
  pendingFiles = [];
  downloadingFile = false

  constructor(
    location: Location,
    private element: ElementRef,
    private authenticationService: AuthenticationService,
    private notifier: NotifierService,
    public auth: AuthGuard,
    private reportService: ReportsService,
    private toastr: ToastrService
  ) {
    this.location = location;
    this.sidebarVisible = false;

    this.getPendingFiles();
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.route = this.location.prepareExternalUrl(this.location.path());
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.notifier.notifier$.pipe().subscribe(message => {
      this.sidebarVisible = message ? !!message.visible : this.sidebarVisible;
      if (message && message.newFile) {
        this.getPendingFiles();
      }
    });
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.notifier.notify({visible: true});
      this.sidebarOpen();
    } else {
      this.notifier.notify({visible: false});
      this.sidebarClose();
    }
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());

    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Landing';
  }

  getIcon() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].icon;
      }
    }
    return 'pe-7s-graph';
  }

  async getPendingFiles() {
    const pendingFiles = localStorage.getItem('pendingFiles') ?
      JSON.parse(localStorage.getItem('pendingFiles')) : [];

    this.pendingFiles = pendingFiles.map(fileName => {
      return {name: fileName, ready: false};
    });
  }

  private updatePendingFileStatus(fileName: string) {
    this.pendingFiles.map(file => {
      if (file.name === fileName) {
        file.ready = true;
      }
    })
  }

  checkFileStatuses() {
    this.pendingFiles.forEach(file => {
      this.checkFile(file.name);
    })
  }

  checkFile(fileName: string) {
    const fileNameWithExtension = fileName + '.' + environment.filePreferredExtension;

    this.reportService.fileIsReady(fileNameWithExtension)
    .then((response: any) => {
      if (response.fileExists) {
        this.updatePendingFileStatus(fileName)
      }
    }).catch(err => {
      this.toastr.error('Ocurrió un error al generar el reporte');
    })
  }

  getFile(fileName) {
    this.downloadingFile = true;
    const fileNameWithExtension = fileName + '.' + environment.filePreferredExtension;

    setTimeout(() => {
      window.location.href = environment.reportsFolder + '/' + fileNameWithExtension;
      this.removeFileFromStorage(fileName);
      this.downloadingFile = false;
    }, 5000);
  }

  private removeFileFromStorage(fileName: string) {
    this.pendingFiles = this.pendingFiles.filter(file => file.name !== fileName)

    let pendingFiles = localStorage.getItem('pendingFiles') ?
      JSON.parse(localStorage.getItem('pendingFiles')) : [];

    pendingFiles = pendingFiles.filter(file => file !== fileName);

    localStorage.setItem('pendingFiles', JSON.stringify(pendingFiles))
  }

  logout() {
    this.authenticationService.logout();
  }
}
