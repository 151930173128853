import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    private jwt = new JwtHelperService();
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;

        if (currentUser && currentUser.token) {
            if (this.isLogged()) {
                if (route.data.roles && route.data.roles.indexOf(currentUser.role.name) === -1) {
                    this.router.navigate(['/home/landing']);
                    return false;
                }

                return true;
            } else {
                this.authenticationService.logout();
            }
        }

        this.router.navigate(['/login'], { queryParams: {returnUrl: state.url}});
        return false;
    }

    getExpiration() {
        const token = this.jwt.decodeToken(this.getToken());

        return token ? moment(moment.unix(token.exp)) : null;
    }

    isLogged() {
        return this.getExpiration() ? moment().isBefore(this.getExpiration()) : false;
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    public is(role) {
        const userRole = this.getUser().role.name;

        if (!role) {
            return true;
        }

        if (Array.isArray(role)) {
            return role.includes(userRole);
        }

        if (role === userRole) {
            return true;
        }

        return false;
    }
}