import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ReportsService {
    constructor(
        private http: HttpClient
    ) { }

    getMessageReportForCompany(cid: string, from: any = null, to: any = null) {
      let params = new HttpParams().set('cid', cid)

      if (from && to) {
        params = new HttpParams().set('cid', cid).set('from', from).set('to', to);
      }

      return this.http.get(`${environment.baseUrl}/report/message`, {params}).toPromise();
    }

    requestReport(cid: string, from: any = null, to: any = null, account = '') {
      let params = new HttpParams().set('cid', cid).set('account', account)

      if (from && to) {
        params = new HttpParams().set('cid', cid).set('from', from).set('to', to).set('account', account);
      }

      return this.http.get(`${environment.baseUrl}/report/message/details`, {params}).toPromise();
    }

    requestResponsesReport(cid: string, from: any = null, to: any = null, account = '') {
      let params = new HttpParams().set('cid', cid).set('account', account)

      if (from && to) {
        params = new HttpParams().set('cid', cid).set('from', from).set('to', to).set('account', account);
      }

      return this.http.get(`${environment.baseUrl}/report/message/user-responses`, {params}).toPromise();
    }

    fileIsReady(filename: string) {
      const params = new HttpParams().set('fileName', filename)

      return this.http.get(`${environment.baseUrl}/report/file`, {params}).toPromise()
    }

    requestReportByOperator(from = '', to = '', account = '') {
      const params = new HttpParams()
        .set('from', from ? from : '')
        .set('to', to ? to : '')
        .set('account', account);

      return this.http.get(`${environment.baseUrl}/report/message/operator`, {params}).toPromise();
    }
}
