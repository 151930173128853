import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../domain/user';
import { environment } from '../../environments/environment';
import { CreditsUpdateInterface } from '../interfaces/CreditsUpdateInterface';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(
        private http: HttpClient
    ) { }

    getAll() {
        return this.http.get<any>(`${environment.baseUrl}/users`).toPromise();
    }

    updateCredits(data: CreditsUpdateInterface) {
        return this.http.put<any>(`${environment.baseUrl}/client/credits`, data).toPromise();
    }

    passwordChange(data: any) {
        return this.http.put<any>(`${environment.baseUrl}/user/password`, data).toPromise();
    }
}
