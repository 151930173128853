import { Directive, HostListener, ElementRef, Renderer } from '@angular/core';

/**
* Allows the sidebar to be toggled via click.
*/
@Directive({
  selector: '[appSidebarToggle]'
})
export class SidebarToggleDirective {

  constructor(private el: ElementRef, private renderer: Renderer) {

  }

  @HostListener('click') onClick() {
    document.querySelector('.main-panel').classList.toggle('full-body');
    document.querySelector('.sidebar').classList.toggle('sidebar-hide');
    document.querySelector('.sidebar').classList.toggle('sidebar-show');
  }
}
